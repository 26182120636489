/// Menu
import React, { Component, useContext, useEffect, useReducer, useState } from "react";
//import Metismenu from "metismenujs";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

/// Link
import { Link, NavLink } from "react-router-dom";

import {MenuList} from './Menu';
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

//import medal from "../../../images/medal.png";


// class MM extends Component {
// 	componentDidMount() {
// 		this.$el = this.el;
// 		this.mm = new Metismenu(this.$el);
// 	}
//   componentWillUnmount() {
//   }
//   render() {
//     return (
//       <div className="mm-wrapper">
//         <ul className="metismenu " ref={(el) => (this.el = el)}>
//           {this.props.children}
//         </ul>
//       </div>
//     );
//   }
// }

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active : "",
  activeSubmenu : "",
}

const SideBar = () => {
	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
	} = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);	

  // let handleheartBlast = document.querySelector('.heart');
  // function heartBlast(){
  //   return handleheartBlast.classList.toggle("heart-blast");
  // }

	useEffect(() => {
				
			
	}, []);
 //For scroll
 	const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)

	// Menu dropdown list 
	//const [active , setActive] = useState('');
	//const [activeSubmenu , setActiveSubmenu] = useState('');

 
  const handleMenuActive = status => {
		
    setState({active : status});
		
		if(state.active === status){
			
      setState({active : ""});
    }
   
	}
	const handleSubmenuActive = (status) => {
		
    setState({activeSubmenu : status})
		if(state.activeSubmenu === status){
      setState({activeSubmenu : ""})
			
		}
    
	}
	// Menu dropdown list End

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

	
  return (
    <div className={`deznav  border-right ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">         
          <ul className="metismenu" id="menu">
          <li className="nav-item dropdown notification_dropdown ">
			<Link to = "./addorder">
				<Button className="me-2 " variant="success">
				B
			</Button>
			</Link>
			</li>
			<li className="nav-item dropdown notification_dropdown ">
				<Link to ="./addorder"><Button className="me-2" variant="danger">
					S
				</Button>	
				</Link>
			</li>
			<li className="nav-item dropdown notification_dropdown ">
				<Link to ="./addorder"><Button className="me-2" variant="primary">
					P
				</Button>
				</Link>
			</li>
			<li className="nav-item dropdown notification_dropdown ">
				<Link to ="./partyledger"><Button className="me-2" variant="primary">
					PL
				</Button>
				</Link>
			</li>
			<li className="nav-item dropdown notification_dropdown ">
				<Link to ="./Listusers"><Button className="me-2" variant="primary">
					L
				</Button>
				</Link>
			</li>
          </ul>		  
        </PerfectScrollbar>
      </div>
    );
};

export default SideBar;
