import React,{useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ReactSlider from 'react-slider'

import { Button } from "react-bootstrap";
import  DatePicker  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Nouislider from "nouislider-react";
//import noUiSlider from "nouislider";
//import "nouislider/distribute/nouislider.css";
//import 'nouislider/dist/nouislider.css';

//let slider;

// function destroyExistingSlider(){
//   if(slider && slider.noUiSlider){
//     slider.noUiSlider.destroy();
//   }
// }

const options = [
	{ value: "chocolate", label: "Chocolate" },
	{ value: "strawberry", label: "Strawberry" },
	{ value: "vanilla", label: "Vanilla" },
  ];
  const types = [
	{ value: "Buy", label: "Buy" },
	{ value: "Sell", label: "Sell" },
	{ value: "Payment", label: "Payment" },
  ];

const OrderForm = () =>{
	// useEffect(()=>{
	// 	//destroyExistingSlider();
	// 	var slider = document.getElementById('slider');
	// 	noUiSlider.create(slider, {
	// 		start: [20, 80],
	// 		connect: true,
	// 		range: {
	// 			'min': 0,
	// 			'max': 100
	// 		}
	// 	});
	//});	
	const [selectedOption, setSelectedOption] = useState(null);  
	return(
		<>
			<form>
				<div className="row">
					<div className="col-md-3 mb-3">
						<div className="example rangeDatePicker">
						<label className="form-label text-primary">Date</label>
							<DatePicker  className="form-control"/> 
						</div>
					</div>
					<div className="col-md-2 mb-3">
						<div className="example rangeDatePicker">
						<label className="form-label text-primary">Type</label>
						<Select 
							options={types} 
							defaultValue={types[0]}
							className="custom-react-select me-2"
						/>
						</div>
					</div>
					<div className="col-md-5 mb-3">
						<div className="example rangeDatePicker">
							<label className="form-label text-primary">Select Party</label>
							<Select 
								options={options} 
								defaultValue={options[0]}
								className="custom-react-select ms-2"
							/>
						</div>
					</div>
					<div className="col-md-2 mb-3">
						<div className="text-center">
						<label className="form-label text-primary">Select Party</label>
							<Link to={"/exchange"} className="btn btn-primary w-75">Submit</Link>
						</div>
					</div>
				</div>
				{/* <div className="text-center">
					<Button className="me-2" variant="primary">
					Primary
					</Button>
				</div>
					 */}
			</form>
		</>
	)
}
export default OrderForm;