import React,{useState} from 'react';
import { Button } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import DatePicker from "react-datepicker";
import Select from "react-select";

//images
import studio1 from './../../images/studio/1.jpg';
import studio2 from './../../images/studio/2.jpg';
import studio3 from './../../images/studio/3.jpg';
import studio4 from './../../images/studio/4.jpg';
import studio5 from './../../images/studio/5.jpg';

// const  TargetDonutChart = loadable(() =>
// 	pMinDelay(import("../Crypto/Banking/TargetDonutChart"), 1000)
// );

const transactionBlog = [
    {image:studio1, title:'Portu Studio', price:'$ 150,036.12', date:'June 2, 2022'},
    {image:studio2, title:'Akademi Studio', price:'$ 120,036.16', date:'July 5, 2022'},
    {image:studio3, title:'Nextrun Studio', price:'$ 300,036.25', date:'July 15, 2022'},
    {image:studio4, title:'Geex Studio', price:'$ 650,036.12', date:'Aug 10, 2022'},
    {image:studio5, title:'Kleon Studio', price:'$ 780,036.31', date:'Sep 25, 2022'},
];
const cardTitle = [
    {title:'Income'},
    {title:'Outcome'},
];

const options = [
	{ value: "Party name", label: "Party name" },
	{ value: "Party Name", label: "Party name" },
	{ value: "Party name", label: "Party name" },
];
const types = [
	{ value: "Itemname1", label: "Item Name1" },
	{ value: "Itemname2", label: "Item Name2" },
	{ value: "Itemname3", label: "Item Name3" },
];
const tableData = [
	{ itemaname: 'Item', qty: '10000', price: '100000', amount: '1000000000' },
	{ itemaname: 'Item', qty: '10000', price: '100000', amount: '1000000000' },
	{ itemaname: 'Item', qty: '10000', price: '100000', amount: '1000000000' },
	{ itemaname: 'Item', qty: '10000', price: '100000', amount: '1000000000' },
	{ itemaname: 'Item', qty: '10000', price: '100000', amount: '1000000000' },
];

const Home = () =>{
    const [dropSelect, setDropSelect] = useState('This Month');

    return(
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12 col-sm-6">
							<div className="card h-auto">
                                <div className="card-header justify-content-between border-0">
                                    <h2 className="heading mb-0">Stock Report</h2>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_732_458)">
                                            <path d="M23 19H18.93C18.6648 19 18.4104 18.8946 18.2229 18.7071C18.0354 18.5196 17.93 18.2652 17.93 18V15H6V18C6 18.2652 5.89464 18.5196 5.70711 18.7071C5.51957 18.8946 5.26522 19 5 19H1C0.734784 19 0.48043 18.8946 0.292893 18.7071C0.105357 18.5196 0 18.2652 0 18V8C0 7.20435 0.31607 6.44129 0.87868 5.87868C1.44129 5.31607 2.20435 5 3 5H21C21.7956 5 22.5587 5.31607 23.1213 5.87868C23.6839 6.44129 24 7.20435 24 8V18C24 18.2652 23.8946 18.5196 23.7071 18.7071C23.5196 18.8946 23.2652 19 23 19ZM19.93 17H22V8C22.0015 7.86827 21.9766 7.73757 21.9269 7.61558C21.8771 7.49359 21.8035 7.38276 21.7104 7.28961C21.6172 7.19646 21.5064 7.12285 21.3844 7.07312C21.2624 7.02339 21.1317 6.99853 21 7H3C2.86827 6.99853 2.73757 7.02339 2.61558 7.07312C2.49359 7.12285 2.38276 7.19646 2.28961 7.28961C2.19646 7.38276 2.12285 7.49359 2.07312 7.61558C2.02339 7.73757 1.99853 7.86827 2 8V17H4V14C4 13.7348 4.10536 13.4804 4.29289 13.2929C4.48043 13.1054 4.73478 13 5 13H18.93C19.1952 13 19.4496 13.1054 19.6371 13.2929C19.8246 13.4804 19.93 13.7348 19.93 14V17Z" fill="#A098AE" />
                                            <path d="M18.933 7H5C4.73478 7 4.48043 6.89464 4.29289 6.70711C4.10536 6.51957 4 6.26522 4 6V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0L18.933 0C19.1982 0 19.4526 0.105357 19.6401 0.292893C19.8276 0.48043 19.933 0.734784 19.933 1V6C19.933 6.26522 19.8276 6.51957 19.6401 6.70711C19.4526 6.89464 19.1982 7 18.933 7ZM6 5H17.933V2H6V5ZM17.033 24H6.9C6.13087 24 5.39325 23.6945 4.84939 23.1506C4.30554 22.6068 4 21.8691 4 21.1V14C4 13.7348 4.10536 13.4804 4.29289 13.2929C4.48043 13.1054 4.73478 13 5 13H18.93C19.1952 13 19.4496 13.1054 19.6371 13.2929C19.8246 13.4804 19.93 13.7348 19.93 14V21.1C19.93 21.8686 19.6249 22.6058 19.0817 23.1495C18.5385 23.6933 17.8016 23.9992 17.033 24ZM6 15V21.1C6 21.3387 6.09482 21.5676 6.2636 21.7364C6.43239 21.9052 6.66131 22 6.9 22H17.033C17.2717 22 17.5006 21.9052 17.6694 21.7364C17.8382 21.5676 17.933 21.3387 17.933 21.1V15H6ZM20 10H19C18.7348 10 18.4804 9.89464 18.2929 9.70711C18.1054 9.51957 18 9.26522 18 9C18 8.73478 18.1054 8.48043 18.2929 8.29289C18.4804 8.10536 18.7348 8 19 8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9C21 9.26522 20.8946 9.51957 20.7071 9.70711C20.5196 9.89464 20.2652 10 20 10Z" fill="#A098AE" />
                                            <path d="M15 18H9C8.73478 18 8.48043 17.8946 8.29289 17.7071C8.10536 17.5196 8 17.2652 8 17C8 16.7348 8.10536 16.4804 8.29289 16.2929C8.48043 16.1054 8.73478 16 9 16H15C15.2652 16 15.5196 16.1054 15.7071 16.2929C15.8946 16.4804 16 16.7348 16 17C16 17.2652 15.8946 17.5196 15.7071 17.7071C15.5196 17.8946 15.2652 18 15 18ZM12 21H9C8.73478 21 8.48043 20.8946 8.29289 20.7071C8.10536 20.5196 8 20.2652 8 20C8 19.7348 8.10536 19.4804 8.29289 19.2929C8.48043 19.1054 8.73478 19 9 19H12C12.2652 19 12.5196 19.1054 12.7071 19.2929C12.8946 19.4804 13 19.7348 13 20C13 20.2652 12.8946 20.5196 12.7071 20.7071C12.5196 20.8946 12.2652 21 12 21Z" fill="#A098AE" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_732_4581">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="card-body px-0 pt-1">
									<div className="sell-element">
										<form>
											<div className="row">
												<div className="col-md-10 mb-3">
													<div className="example rangeDatePicker">
														<label className="form-label text-primary">Select Item</label>
														<Select
															options={types}
															defaultValue={types[0]}
															className="custom-react-select me-2"
														/>
													</div>
												</div>
												<div className="col-md-2">
													<div className="text-center">
														<label className="form-label text-primary"></label><br />
														<Link to={"/exchange"} className="btn btn-primary w-75">Show</Link>
													</div>
												</div>
											</div>
											{/* <div className="text-center">
											<Button className="me-2" variant="primary">
											Primary
											</Button>
										</div>
											*/}
										</form>
									</div>
								</div>
							</div>
						</div>
                        <div className="col-xl-12">
                            <div className="card">
                                
                                <div className="card-body px-0 py-0">
                                    <div id="history_wrapper" className="table-responsive dataTablehistory">
                                        <div className="dataTables_wrapper no-footer">
                                            <table id="example" className="table shadow-hover dataTable display" style={{ minWidth: "845px" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Sr.</th>
                                                        <th>Itemname</th>
                                                        <th>Qty</th>
                                                        <th>Rate</th>
                                                        <th>Amount</th>
                                                        {/* <th className="text-end">Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>1</td>
                                                            <td>{item.itemaname}</td>
                                                            <td>{item.qty}</td>
                                                            <td>{item.price}</td>
                                                            <td>{item.amount}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Home;